export default {
  cake: {
    56: '0x7762A14082Ab475C06D3868B385E46aE27017231',
    97: '0x599b470EaE66fcB961014B058E8Be260F143d52A',
  },
  masterChef: {
    56: '0x75dDFFF9810eE13AC45d49D6606B2fBFE9B90FB0',
    97: '0x4440aFa050185A27C85900ACAC2AED45f387fca9',
  },

  prvNFT: {
    56: '0x75dDFFF9810eE13AC45d49D6606B2fBFE9B90FB0',
    97: '0x4440aFa050185A27C85900ACAC2AED45f387fca9',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xA73D61379627dB94f968C7bb4BD7fF5c07723Ec2',
  },
  lottery: {
    56: '0x938062218d298A5a6B8c44a723A4A7177D2761cA',
    97: '0x78f97C5dF7FC51E68DeA698DB7EF1DA96681f880',
  },
  lotteryNFT: {
    56: '0xDA8B8Fc2aCC81168401F745cBACeC461c2889De3',
    97: '0x8604B06459c3e7200355DcE354921E3012cDD43F',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0x599b470EaE66fcB961014B058E8Be260F143d52A',
  },
  eth: {
    56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    97: '',
  },
  referral: {
    56: '0xE5660fe1A8b21316c5c73417B950181145B3e4eA',
    97: '0x36661b99150aB51f4879F0EA7A5d9C90D7c320Fa',
  },
  forest: {
    56: '0x6DC799bc0CcC3aA49c2565C435131842D40e235a',
    97: '0x67CA3eB4b315Ee45d5F08a22B8425dC5b6a53626', // not tested
  },

  busdPresale: {
    56: '0x836e9321aa6903b7dDB006aA2c61Ae5428912bD8',
    97: '0x9938260ADAf068140F01C044d2EC9feDE938Cb7B', // not tested
  },
  prvPresale: {
    56: '0x588546AF5C33c893445bf6D4d175DfbeF802261D',
    97: '0x588546AF5C33c893445bf6D4d175DfbeF802261D', // not tested
  },
  prePrv2:{
    56: '0xd1d9ACcE854b4ADDd24fd441B1e202415Dfb02aE',
    97: '0xd1d9ACcE854b4ADDd24fd441B1e202415Dfb02aE', // not tested
  },
  prePrvg:{
    56: '0x24dE9b7ba401adb1ABB297Af42992228504A8A3A',
    97: '0x24dE9b7ba401adb1ABB297Af42992228504A8A3A', // not tested
  },

}
